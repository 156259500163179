// Copyright 2022 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import HelpOutline from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  text: string;
}

const HelpTooltip = ({ text }: Props) => {
  return (
    <Tooltip arrow title={text}>
      <IconButton
        aria-label="What is this?"
        sx={{
          verticalAlign: 'middle',
          marginTop: '-8px',
          marginBottom: '-8px',
        }}
      >
        <HelpOutline />
      </IconButton>
    </Tooltip>
  );
};

export default HelpTooltip;
